* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

p {
  font-size: 16px;
}

a {
  text-decoration: none !important;
}

body {
  margin: 0;
  font-family: "Quicksand", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Color Edificar */
.text-edificar {
  background: #E85A20; 
}

.bg-edificar {
background: #E85A20; 
}

.btn-edificar { 
color: #FFFFFF; 
background-color: #E85A20; 
border-color: #ffefe9;
} 

.btn-edificar:hover, 
.btn-edificar:focus, 
.btn-edificar:active, 
.btn-edificar.active, 
.open .dropdown-toggle.btn-edificar { 
color: #FFFFFF; 
background-color: #FF6629; 
border-color: #ffefe9;
outline: none;
} 

.btn-edificar:active, 
.btn-edificar.active, 
.open .dropdown-toggle.btn-edificar { 
background-image: none; 
} 

.btn-edificar.disabled, 
.btn-edificar[disabled], 
fieldset[disabled] .btn-edificar, 
.btn-edificar.disabled:hover, 
.btn-edificar[disabled]:hover, 
fieldset[disabled] .btn-edificar:hover, 
.btn-edificar.disabled:focus, 
.btn-edificar[disabled]:focus, 
fieldset[disabled] .btn-edificar:focus, 
.btn-edificar.disabled:active, 
.btn-edificar[disabled]:active, 
fieldset[disabled] .btn-edificar:active, 
.btn-edificar.disabled.active, 
.btn-edificar[disabled].active, 
fieldset[disabled] .btn-edificar.active { 
background-color: #E85A20; 
border-color: #ffefe9;
} 

.btn-edificar .badge { 
color: #E85A20; 
background-color: #FFFFFF; 
}


/* BTN-BLUE */

.text-blue {
background-color: #009ADE; 
}

.bg-blue {
background-color: #009ADE; 
}

.btn-blue { 
color: #ffffff; 
background-color: #009ADE; 
/* border-color: #FFFFFF;  */
} 

.btn-blue:hover, 
.btn-blue:focus, 
.btn-blue:active, 
.btn-blue.active, 
.open .dropdown-toggle.btn-blue { 
color: #ffffff; 
background-color: #30C1FF; 
border-color: #FFFFFF;
outline: 0;
} 

.btn-blue:active, 
.btn-blue.active, 
.open .dropdown-toggle.btn-blue { 
background-image: none; 
} 

.btn-blue.disabled, 
.btn-blue[disabled], 
fieldset[disabled] .btn-blue, 
.btn-blue.disabled:hover, 
.btn-blue[disabled]:hover, 
fieldset[disabled] .btn-blue:hover, 
.btn-blue.disabled:focus, 
.btn-blue[disabled]:focus, 
fieldset[disabled] .btn-blue:focus, 
.btn-blue.disabled:active, 
.btn-blue[disabled]:active, 
fieldset[disabled] .btn-blue:active, 
.btn-blue.disabled.active, 
.btn-blue[disabled].active, 
fieldset[disabled] .btn-blue.active { 
background-color: #009ADE; 
border-color: #FFFFFF; 
} 

.btn-blue .badge { 
color: #009ADE; 
background-color: #ffffff; 
}


@media screen and (max-width:992px) {
  
  h2 {
    font-size: calc(20px + 0.5vw) !important;
  }
  h1 {
    font-size: calc(25px + 0.5vw) !important;
  }

}