@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500&display=swap');

* {
  font-family: 'Quicksand', sans-serif;
}

button {
  transition: all 0.5s ease-in-out;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media screen and (min-width:996px){
  .Legal {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly !important;
    background-color: #009ADE;
  }
}

@media screen and (max-width:996px){
  .Legal {
    display: flex;
    justify-content: center;
    flex-direction: column;
    
  }

  .Legal a {
    margin-bottom: 8px;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-company {
  background-color: #e94f24;
  color: white;
}

.text-company {
  color: #e94f24;
}

.btn-company-blue {
  background-color: #009ADE;
  color: white;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}
.btn-company-blue:hover {
  /* background-color: #008bc7; */
  color: rgb(233, 233, 233);
}

